import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { GetDataWithToken } from "../../ApiHelper/ApiHelper";
import { useSelector } from "react-redux";

const OrderModal = ({ openModal, modalToggle, setCustomerCode, customerCode, mainCallApi, setDate, date, setDeliveryName, deliveryName, ledger, setIsLoading, setMainData, setCurrentPage, setMainCustomerName }) => {
    const [nextPage, setNextPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [deliveryNameList, setDeliveryNameList] = useState([]);
    const [deliverNameValue, setDeliveryNameValue] = useState('');
    const [callApi, setCallApi] = useState(false);
    const [callApi2, setCallApi2] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [isLoading1, setIsLoading1] = useState(false);
    const roleType = useSelector((state) => state?.user?.userDetails?.Role);

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
        setCallApi(true);
    }

    const deliveryNameHandler = () => {
        setCallApi2(true);
        setNextPage(3);
    }

    const dateChange = (event, key) => {
        // console.log(event.target.value);
        setDate({
            ...date,
            [key]: event.target.value
        });
        // console.log(date);
    };

    const toggle = () => {
        modalToggle();
        setNextPage(0);
    }

    const customerListSubmitHandler = (code, name) => {
        setCustomerName(name);
        setMainCustomerName(name);
        setCustomerCode(code);
        setNextPage(0);
        setCallApi2(true);
    }
    const dateSubmitHandler = () => {
        // mainCallApi(true);
        // toggle(); 
        setNextPage(0);
    }

    const submitSearchData = () => {
        setMainData([]);
        // setIsLoading(true);
        toggle();
        setCurrentPage(1);
        mainCallApi(true);
    }

    const handleInputChange2 = (e) => {
        setDeliveryNameValue(e.target.value);
        setCallApi2(true);
    }
    const deliveryListSubmitHandler = (name) => {
        setDeliveryName(name);

        // setDeliveryName(name)
        //  console.log('dataaaaaaaaaaaaaaaaaa delllllllllll',deliveryName);
        setNextPage(0);
        // mainCallApi(true);
        // toggle();
    }

    useEffect(() => {
        if (callApi) {
            setIsLoading1(true);
            GetDataWithToken(`api/customers/list?filters=SearchText:${searchValue}&pageNo=1&pageSize=10`)
                .then((response) => {
                    setIsLoading1(false);
                    setCustomerList(response);
                    setCallApi(false);
                })
        }
        if (callApi2) {
            setIsLoading1(true);
            GetDataWithToken(`so/deliverynamelist?filters=SearchText:${deliverNameValue}&pageNo=1&pageSize=50`)
                .then(response => {
                    setIsLoading1(false);
                    setDeliveryNameList(response);
                    setCallApi2(false);
                })
        }
    }, [callApi, callApi2])

    return (
        <Modal isOpen={openModal} toggle={toggle} centered>
            {nextPage == 0 && <>
                <ModalHeader toggle={toggle} >
                    Filters
                    {/* <button className="btn">X</button> */}
                </ModalHeader>
                <ModalBody>
                    <p className="ms-2 d-flex align-items-center justify-content-between" >
                        <button
                            className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                            onClick={() => setNextPage(1)}
                            // onClick={() => { setNextPage(1); setCallApi(true) }}
                            type="button">Date</button>
                        <p className="mb-0 d-flex align-items-center text-nowrap"> {date?.fromDate && `${date?.fromDate} to ${date?.toDate}`}
                            {date?.fromDate && <button className="btn btn-primary ms-2" onClick={() => setDate({})}>X</button>}
                        </p>
                    </p>
                    {/* <span className="ms-2" onClick={() => setNextPage(1)}>
                        Order date:
                        {date?.fromDate && `${date?.fromDate} to ${date?.toDate}`}
                    </span>
                    {date?.fromDate &&
                        <button className="btn btn-primary ms-2" onClick={() => setDate({})}>X</button>
                    } */}
                    <hr></hr>
                    {roleType == "ADMIN" || roleType === "SALES_PERSON" ?
                        <>
                            <p className="ms-2 d-flex align-items-center justify-content-between" >
                                <button
                                    className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                                    onClick={() => { setNextPage(2); setCallApi(true) }}
                                    // onClick={() => { setNextPage(1); setCallApi(true) }}
                                    type="button">Customer</button>
                                <p className="mb-0 d-flex align-items-center text-nowrap"> {customerName}
                                    {customerName && <button className="btn btn-primary ms-2" onClick={() => { setCustomerName(''); setCustomerCode('') }}>X</button>}
                                </p>
                            </p>
                            <hr></hr>
                        </> : ""
                    }
                    {/* <span className="ms-2 w-50" onClick={() => { setNextPage(2); setCallApi(true) }}>Customer:{customerName}
                        <button className="btn">X</button>
                    </span>
                    {customerName && <button className="btn btn-primary ms-2" onClick={() => { setCustomerName(''); setCustomerCode('') }}>X</button>} */}


                    {!ledger && (roleType == "ADMIN" || roleType == "SALES_PERSON") ?
                        <>
                            <p className="ms-2 d-flex align-items-center justify-content-between" >
                                <button
                                    className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                                    onClick={deliveryNameHandler}
                                    // onClick={() => { setNextPage(1); setCallApi(true) }}
                                    type="button">Delivery Name</button>
                                <p className="mb-0 d-flex align-items-center text-nowrap"> {deliveryName}
                                    {deliveryName && <button className="btn btn-primary ms-2" onClick={() => { setDeliveryName('') }}>X</button>}
                                </p>
                            </p>
                            {/* <span className="ms-2 mb-5" onClick={deliveryNameHandler} >Delivery name:{deliveryName}
                            </span>
                            {deliveryName && <button className="btn btn-primary ms-2" onClick={() => { setDeliveryName('') }}>X</button>}
                            <hr></hr>  */}
                        </> : ""
                    }
                    <div>
                        <button className="btn btn-primary ms-2"
                            onClick={submitSearchData}
                        >Search
                        </button>
                    </div>
                </ModalBody>
            </>
            }
            {nextPage == 1 &&
                <>
                    <div className="d-flex justify-content-between  mt-2 px-3 ">
                        <h5>SELECT OPTION</h5>
                        <span onClick={() => setNextPage(0)}>
                            <i class="bx bx-arrow-back"></i>
                        </span>
                    </div>
                    <ModalBody>
                        <div>
                            <label>select date from:</label>
                            {/* <DatePicker className="me-sm-2  form-control"
                            
                            onChange={(value) => dateChange(value,'fromDate')}
                                selected={date?.fromDate}
                                required
                                maxDate={new Date()?.setDate(new Date()?.getDate() - 1)}
                        /> */}
                            <input type="date" className="form-control" id="start" name="trip-start" value={date?.fromDate}
                                max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                                onChange={(e) => dateChange(e, 'fromDate')} />
                        </div>
                        <div style={{ marginTop: '4px' }}>
                            <label>select date to: </label>
                            {/* <DatePicker className="me-sm-2  form-control"
                                
                            onChange={(value) => dateChange(value,'toDate')}
                                selected={date?.toDate}
                                required
                                maxDate={new Date()}
                            />              */}
                            <input type="date" id="end" className="form-control" value={date?.to} max={new Date().toISOString().split('T')[0]} onChange={(value) => dateChange(value, 'toDate')} />
                        </div>
                        <button className="btn  btn-primary my-2" onClick={dateSubmitHandler}>submit</button>
                    </ModalBody>
                </>}
            {nextPage == 2 &&
                <>
                    <div className="d-flex justify-content-between  mt-2 px-3 ">
                        <h5>SELECT OPTION</h5>
                        <span onClick={() => setNextPage(0)}>
                            <i class="bx bx-arrow-back"></i>
                        </span>
                    </div>
                    <ModalBody>
                        <div className="d-flex mb-2">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                onChange={handleInputChange}
                            />

                        </div>
                        <ul class="list-group list-group-flush">
                            {customerList.length === 0 && !isLoading1 && <div className="text-center">No data data found</div>}
                            {isLoading1 ? <div className="text-center" ><Spinner /></div> : customerList?.map((data) => <li class="list-group-item d-flex justify-content-between" onClick={() => customerListSubmitHandler(data?.Code, data?.Name)}>{data?.Name}</li>)}
                        </ul>
                    </ModalBody>
                </>
            }
            {nextPage == 3 && <>
                <div className="d-flex justify-content-between  mt-2 px-3 ">
                    <h5>SELECT OPTION</h5>
                    <span onClick={() => setNextPage(0)}>
                        <i class="bx bx-arrow-back"></i>
                    </span>
                </div>
                <ModalBody>
                    <div className="d-flex mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={handleInputChange2}
                        />
                        {/* <button className="btn btn-primary ms-2"
                            // onClick={submitSearchData}
                        >Search
                    </button> */}
                    </div>
                    <ul class="list-group list-group-flush">
                        {deliveryNameList.length === 0 && !isLoading1 && <div className="text-center">No data data found</div>}
                        {isLoading1 ? <div className="text-center" ><Spinner /></div> : deliveryNameList?.map((data) => <li class="list-group-item d-flex justify-content-between"
                            onClick={() => deliveryListSubmitHandler(data?.Code)}>{data?.Name}</li>
                        )}
                    </ul>
                </ModalBody>
            </>
            }
        </Modal>
    )
}

export default OrderModal;
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import Cookies from "js-cookie";
import { GetDataWithToken, serverUrl } from "../ApiHelper/ApiHelper";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
import { useSelector } from "react-redux";

const InvoiceDetail = () => {
    const location = useLocation();
    const roleType = useSelector((state) => state?.user?.userDetails?.Role);
    const [detailData, setDetailData] = useState();
    let tokens = Cookies.get("F&ftToken");
    const [downloadLoading, setDownloadLoading] = useState(false);

    const downloadHandler = () => {
        setDownloadLoading(true);
        GetDataWithToken(`invoice/pdf?code=${location?.state?.id}`)
            .then(response => {
                axios({
                    // url: `http://tailoring.fandf.in/api/items/list?pageNo=${currentPage}&filters=${searchData?"SearchText:":""}${searchData?searchData:""};CategoryCode:${categoryCode};CollectionCode:${searchCollectionData};BrandCode:${searchBrandData}&excel=true`,
                    url: `${serverUrl}report/download?fileName=${response}&type=pdf`,
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + tokens,
                        "Accept-Language": "en",
                    },
                    responseType: "blob",
                    // important
                }).then((response) => {
                    //    setDownloadData(response);
                    //   console.log("responseeeeee",response);
                    setDownloadLoading(false);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${detailData?.Code}_${detailData?.DeliveryName}_${String(new Date().getTime())}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    // setLoadingData(false);
                });
            })
    }
    useEffect(() => {
        GetDataWithToken(`invoice/details?code=${location?.state?.id}`)
            .then((response) => {
                setDetailData(response);
            })
    }, [])
    return (
        <Layout>
            {downloadLoading ? <>
                <div className="text-center pt-5 mt-5">
                    <Spinner /><p>Downloading Please Wait...</p></div>
            </> :
                <div className="content-body">
                    <div className="container-fluid px-0">
                        <Heading
                            name={"Invoice Details"}
                            // breadCrumb1={"Invoices"}
                            // breadCrumb2={"Invoice-Details"}
                            DownloadReportHandler={downloadHandler}
                        />
                        <div className="row">
                            <div className="col-12">
                                <div className="card p-2">
                                    {/* <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-11">
                                            <h4 className="card-title">Invoice details</h4>
                                        </div>
                                        <div className="col-lg-1">
                                            <button className="btn btn-primary" onClick={downloadHandler}>
                                                <i className='bx bxs-file-export'>PDF</i>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <ul class="list-group mb-lg-0 mb-2">
                                                <li class="list-group-item d-flex justify-content-between ">
                                                    <strong>Invoice No:</strong>
                                                    <span class="mb-0">{detailData?.Code}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <strong>Invoice Date:</strong>
                                                    <span class="mb-0">{moment(detailData?.InvoiceDate)?.format('DD/MM/YYYY')}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Order No:</strong>
                                                    <span class="mb-0">{detailData?.OrderCode}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Order Date:</strong>
                                                    <span class="mb-0">{moment(detailData?.OrderDate)?.format('DD/MM/YYYY')}</span>
                                                </li>

                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>AWB No.</strong>
                                                    <span class="mb-0">{detailData?.AwbNo}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Type</strong>
                                                    <span class="mb-0">{detailData?.Type}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Delivery Name</strong>
                                                    <span class="mb-0">{detailData?.DeliveryName}</span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="col-sm-6">
                                            <ul class="list-group ">
                                                <li class="list-group-item d-flex justify-content-between ">
                                                    <strong>Delivery Mode</strong>
                                                    <span class="mb-0">{detailData?.DeliveryMode}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Transport</strong>
                                                    <span class="mb-0">{detailData?.Transport}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Sales Person Name</strong>
                                                    <span class="mb-0">{detailData?.SalesPersonName}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Sales Person Email</strong>
                                                    <span class="mb-0">{detailData?.SalesPersonEmail?.split(';')?.map((data) => <span style={{ justifyContent: "end", display: "block" }}>{data}</span>)}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Sales Person Contact</strong>
                                                    <span class="mb-0">{detailData?.SalesPersonContact}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Remarks:</strong>
                                                    <span class="mb-0">{detailData?.Remarks}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="col-lg-3">
                                            <h4 className="card-title">items</h4>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="mt-2 table  table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            item
                                                        </th>

                                                        <th>
                                                            Quantity
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailData?.Lines?.map((data) =>
                                                        < tr >
                                                            <td>
                                                                {data?.ItemName}
                                                            </td>
                                                            <td>
                                                                {data?.Qty && +Math?.abs(data?.Qty)?.toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            {/* <div className={styles.container} >
                <div className={styles.col_1}>
                    <div className={styles.list}>
                         <ul class="list-group list-group-flush">
                                                <li class="list-group-item d-flex justify-content-between my-2 ">                                          
                                                    <strong>Invoice No:</strong>
                                                    <span class="mb-0">{ detailData?.Code}</span>
                                                </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                    <strong>Invoice Date:</strong>
                                                    <span class="mb-0">{moment(detailData?.InvoiceDate)?.format('DD/MM/YYYY') }</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                   
                                                    <strong>Order No:</strong>
                                                    <span class="mb-0">{detailData?.OrderCode}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Order Date:</strong>
                                                    <span class="mb-0">{ moment(detailData?.OrderDate)?.format('DD/MM/YYYY')}</span>
                                            </li>
                                                
                                               <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>AWB No.</strong>
                                                    <span class="mb-0">{detailData?.AwbNo }</span>
                                                </li>
                                                 <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Type</strong>
                                                    <span class="mb-0">{ detailData?.Type }</span>
                                                </li>
                                                 <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Delivery Name</strong>
                                                    <span class="mb-0">{ detailData?.DeliveryName }</span>
                                                </li>
                                            </ul>
                    </div>
                    <div className={styles.list}>
                       <ul class="list-group list-group-flush">
                                                <li class="list-group-item d-flex justify-content-between ">                                          
                                                    <strong>Delivery Mode</strong>
                                                    <span class="mb-0">{ detailData?.DeliveryMode}</span>
                                                </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Transport</strong>
                                                    <span class="mb-0">{ detailData?.Transport }</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                   
                                                    <strong>Sales Person Name</strong>
                                                    <span class="mb-0">{ detailData?.SalesPersonName }</span>
                                                </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                   
                                                    <strong>Sales Person Email</strong>
                                                    <span class="mb-0">{detailData?.SalesPersonEmail?.split(';')?.map((data) => <p style={{justifyContent:"end"}}>{data}</p>)}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Sales Person Contact</strong>
                                                    <span class="mb-0">{ detailData?.SalesPersonContact}</span>
                                            </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Remarks:</strong>
                                                    <span class="mb-0">{ detailData?.Remarks}</span>
                                            </li>
                                            </ul>
                    </div>
                </div>
                <div className={styles.col_2}>
                    <div><h3>Items:</h3></div>
                    <div>
                       <Table bordered>
                                          <thead>
                                             <tr>
                                               <th>
                                               item
                                            </th>
    
                                                    <th>
                                              Quantity
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {detailData?.Lines?.map((data) =>
                                                    < tr >
                                                         <td>
                                                            {data?.ItemName}
                                                         </td>
                                                         <td>
                                                           {data?.Qty&&+Math?.abs(data?.Qty)?.toFixed(2)}
                                                        </td>
                                            </tr>
                                           )}
                                        </tbody>
                                        </Table>
                    </div> 
                </div>
           </div> */}
        </Layout>
    )
}
export default InvoiceDetail;
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { Spinner, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import Cookies from "js-cookie";
import { GetDataWithToken, serverUrl } from "../ApiHelper/ApiHelper";
import Layout from "../Components/Common/Layout";
import OrderModal from "../Components/Modals/OrderModal";
import Heading from "../Components/Layouts/Heading";
import { useSelector } from "react-redux";
import PageLoader from "../Components/Common/PageLoader";
import DownloadCustomerLedger from "../Components/Modals/DownloadCustomerledger";

const CustomerLedger = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const modalToggle = () => {
    setOpenModal(!openModal);
  };
  const [openDownloadModal, setOpenDownloadModal] = useState(null);
  const downloadModalToggle = () => {
    setOpenDownloadModal(!openDownloadModal);
  };
  const [customerCallApi, setCustomerCallApi] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerData, setCustomerData] = [];
  const [customerCode, setCustomerCode] = useState("");
  const [callApi, setCallApi] = useState(true);
  const { ref: myRef, inView: visibleElement } = useInView();
  const [data, setdata] = useState([]);
  const [OpeningData, setOpeningData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const roleType = useSelector((state) => state?.user?.userDetails?.Role);

  // const [debitTotal, setDebitTotal] = useState(0);
  // const [creditTotal, setCreditTotal] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [date, setDate] = useState({
    fromDate: null,
    toDate: null,
  });

  let tokens = Cookies.get("F&ftToken");

  const filters = [];

  if (searchData) {
    filters.push(`SearchText:${searchData}`);
  }

  if (customerCode) {
    filters.push(`CustomerCode:${customerCode}`);
  } if (customerName) {
    filters.push(`CustomerName:${customerName}`);
  }

  if (date?.fromDate !== null && date?.fromDate !== undefined) {
    filters.push(`FromDate:${date?.fromDate}`);
  }

  if (date?.toDate !== null && date?.toDate !== undefined) {
    filters.push(`ToDate:${date.toDate}`);
  }

  const queryString =
    filters.length > 0
      ? `customer-ledger/list?pageNo=${currentPage}&pageSize=50&filters=${filters.join(";")}`
      : `customer-ledger/list?pageNo=${currentPage}&pageSize=50`;
  const queryStringtotalbalance =
    filters.length > 0
      ? `customer-ledger/total-balance?pageNo=${currentPage}&pageSize=50&filters=${filters.join(
        ";"
      )}`
      : `customer-ledger/total-balance?pageNo=${currentPage}&pageSize=50`;
  const queryString3 =
    filters.length > 0
      ? `customer-ledger/list?pageNo=${currentPage}&filters=${filters.join(";")}`
      : `customer-ledger/list?pageNo=${currentPage}`;


  const DownloadReportHandler = (type) => {
    setDownloadLoading(true);
    GetDataWithToken(queryString3 + `&${type}=true&getAll=true`).then((response) => {
      axios({
        url: type == "excel" ? `${serverUrl}download-excel?fileName=${response}`
          : `${serverUrl}report/download?fileName=${response}&type=pdf`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokens,
          "Accept-Language": "en",
        },
        responseType: "blob",
        // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", type == "pdf" ? `${customerCode}_${customerName}_${String(new Date().getTime())}.pdf` : `${customerCode}_${customerName}_${String(new Date().getTime())}.xlsx`); //or any other extension
        document.body.appendChild(link);
        setDownloadLoading(false);
        link.click();
        // setLoadingData(false);
      });
    });
    // setDownloadLoading(false);
  };

  const toggleDownloadType = (type) => {
    downloadModalToggle();
    DownloadReportHandler(type);
  }



  const pageChangeHandler = (page) => {
    if (page == "-") {
      setCurrentPage((prevPage) => prevPage - 1);
      setCallApi(true);
    }
    if (page == "+") {
      setCurrentPage((prevPage) => prevPage + 1);
      setCallApi(true);
    }
  };

  let balanceAmount =
    Number(OpeningData[0]?.DebitAmount)?.toFixed(2) -
    Math.abs(Number(OpeningData[0]?.CreditAmount))?.toFixed(2);

  useEffect(() => {

    if (roleType === "ADMIN" || roleType === "SALES_PERSON") {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }

  }, []);

  useEffect(() => {
    if (callApi) {
      setIsLoading(true);
      GetDataWithToken(queryString).then((response) => {
        let responseData = response;
        setdata(response);
        setCallApi(false);
        setIsLoading(false);
        setPageLoading(false);
      });
      GetDataWithToken(queryStringtotalbalance).then((response) => {
        setIsLoading(false);
        setOpeningData(response);
        setPageLoading(false);
        setCallApi(false);
      });
    }
  }, [callApi]);
  return (
    <>
      <Layout>
        {downloadLoading ? (
          <>
            <div className="text-center pt-5 mt-5">
              <Spinner />
              <p>Downloading Please Wait...</p>
            </div>
          </>
        ) : (
          <>
            <Heading
              name="Customer Ledger"
              breadCrumb1="Ledger"
              searchData={searchData}
              setSearchData={setSearchData}
              mainSetCallApi={setCallApi}
              modalToggle={modalToggle}
              DownloadReportHandler={downloadModalToggle}
              // mainSetCallApi={setCallApi}
              setMainData={setdata}
              setCurrentPage={setCurrentPage}
              setPageLoading={setPageLoading}
            />
            <div className="card">
              <div className="py-2 px-2">
                {currentPage > 1 && (
                  <button
                    className="btn btn-primary"
                    onClick={() => pageChangeHandler("-")}
                  >
                    -
                  </button>
                )}
                <span className="mx-4">{currentPage}</span>
                <button
                  className="btn btn-primary"
                  onClick={() => pageChangeHandler("+")}
                >
                  +
                </button>
              </div>
              <div>
                <p className="mb-0 p-2">
                  {" "}
                  OPENING BAL: INR{" "}
                  <b>
                    {" "}
                    {OpeningData[0]?.OpeningBalance
                      ? OpeningData[0]?.OpeningBalance?.toFixed(2)
                      : "0"}
                  </b>
                  , CURRENT BAL: INR{" "}
                  <b>
                    {OpeningData[0]?.DebitAmount
                      ? balanceAmount.toFixed(2)
                      : " 0"}
                  </b>{" "}
                </p>
              </div>
              <div className="table-responsive">
                {isLoading && (
                  <h4 style={{ textAlign: "center" }}>Loading...</h4>
                )}
                {data?.length > 0 && !isLoading && (
                  <Table className="table table-striped table-hover">
                    <thead>
                      <tr></tr>
                      <tr>
                        <th> Type </th>
                        <th> Posting Date </th>
                        <th> Doc No.</th>
                        <th> Debit </th>
                        <th> Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((data) => (
                        <tr>
                          <td> {data?.Type} </td>
                          <td>
                            {data?.PostingDate &&
                              moment(data?.PostingDate)?.format("DD/MM/YYYY")}
                          </td>
                          <td>{data?.DocumentCode}</td>
                          <td>
                            {data?.DebitAmount && data?.DebitAmount?.toFixed(2)}
                          </td>
                          <td>
                            {data?.CreditAmount > 0
                              ? -data?.CreditAmount?.toFixed(2)
                              : data?.CreditAmount?.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                      {/* {<tr>
                        <td> {data?.[0]?.Type}</td>
                        <td>
                          {data?.[0]?.PostingDate &&
                            moment(data?.[0]?.PostingDate)?.format("DD/MM/YYYY")}
                        </td>
                        <td>{data?.[0]?.DocumentCode}</td>
                        <td>
                          {data?.[0]?.DebitAmount && data?.[0]?.DebitAmount?.toFixed(2)}
                        </td>
                        <td>
                          {data?.[0]?.CreditAmount > 0
                            ? -data?.[0]?.CreditAmount?.toFixed(2)
                            : data?.[0]?.CreditAmount?.toFixed(2)}
                        </td>
                      </tr>
                      } */}

                    </tbody>
                  </Table>
                )}

                {data?.length == 0 && !isLoading && <div>
                  <h4 style={{ textAlign: "center" }}> No data found </h4>
                </div>}

              </div>
            </div>
          </>
        )}

        {/* {data?.length > 0 && <div ref={myRef} id="scroll"></div>}  */}
      </Layout>
      <OrderModal
        openModal={openModal}
        modalToggle={modalToggle}
        setCustomerCode={setCustomerCode}
        setMainCustomerName={setCustomerName}
        mainCallApi={setCallApi}
        setDate={setDate}
        date={date}
        ledger={"ledger"}
        //setIsLoading={setIsLoading}
        setMainData={setdata}
        setCurrentPage={setCurrentPage}
      />
      <DownloadCustomerLedger
        openModal={openDownloadModal}
        toggle={downloadModalToggle}
        toggleDownloadType={toggleDownloadType}
      />
      {pageLoading && <PageLoader />}
    </>
  );
};
export default CustomerLedger;
